@font-face {
  font-family: "Phetsalath OT";
  src: url("/assets/font/PhetsarathOT.woff") format("woff");
  /* You can also define other font properties like font-weight and font-style if needed */
}
body {
  background-color: #eee;
  margin: 0;
  font-family: "Phetsalath ot", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
