.close{
    color: red;
    font-size: 50px;
    font-weight: bold;
}

.close:hover{
    color: red;
}
.addbtn{
    border-style: dashed;
    outline: #057CAE;
    border: 1;
    border-color: #057CAE !important;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #057CAE;
    font-weight: bold;
}
.addbtn:hover{
    background-color: #057CAE !important;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
}
.addbtn1{
    width: 10%;
    border: 1;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #ffffff;
    font-weight: bold;
    background-color: #057CAE;
}
.addbtn1:hover{
    background-color: #057CAE !important;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
}
.addbtnreset{
    border: 1;
    border-color: #057CAE !important;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #057CAE;
    font-weight: bold;
    margin: 25px;
}
