.editorClassName ,.rdw-editor-main{
    background-color: #F0F0F0;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    /* color: red; */
}
#searchText{
border: none;
background-color: #eee;
}

