#sidenav-block {
  position: fixed;
  display: block;
  margin-left: 200px;
}

.colorItems {
  background-color: #057cae !important;
  /* color: white !important; */
}

.sidenav---sidenav-subnavitem---1cD47 {
  border: none !important;
  /* margin-left: -2px; */
}

.sidenav---navitem---9uL5T {
  background-color: white !important;
  color: #057cae !important;
  margin-top: -8px;
  height: 55px;
  font-size: 14px;
  /* margin-bottom: 5px; */
  /* border-left: 6px solid #7BB500; */
}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  background-color: #057cae !important;
  margin-top: 5px;
  /* color: white !important; */
}

.sidenav---selected---1EK3y .sidenav---navitem---9uL5T {
  background-color: #057cae !important;
  /* color: white !important; */
  margin-bottom: -8px;
}

.sidenav---selected---1EK3y .sidenav---navtext---1AE_f {
  background-color: #057cae !important;
  color: #fff !important;
  margin-left: -10px !important;
}

/* .sidenav---navtext---1AE_f{
  color: white !important;
} */

.sidenav---selected---1EK3y .sidenav---navicon---3gCRo svg {
  color: rgb(255, 255, 255) !important;
}

.sidenav---navicon---3gCRo svg {
  color: rgb(5, 124, 174) !important;
}

/* .sidenav---selected---1EK3y .sidenav---navicon---3gCRo{
  border-left: 6px solid #7BB500;
} */

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo {
  opacity: 1;
}

/* .sidenav---sidenav-subnavitem---1cD47{
  background-color: yellow !important;
} */

.sidenav---selected---1EK3y
  > .sidenav---navitem---9uL5T
  > .sidenav---navtext---1AE_f {
  background-color: #057cae !important;
  color: rgb(255, 255, 255) !important;
}

.sidenav---sidenav-subnav---1EN61 .sidenav---navtext---1AE_f {
  background-color: white !important;
}

.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 .sidenav---navitem---9uL5T{
  margin-left: 40px;
  width: 105%;
  background-color: white !important;
}

.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 .sidenav---navtext---1AE_f{
  width: 100%;
  margin-left: -15px;
}

.sidenav---sidenav-subnav---1EN61 .sidenav---navtext---1AE_f {
  color: rgb(5, 124, 174) !important;
  width: 121%;
}

.sidenav---sidenav-subnav---1EN61
  .sidenav---sidenav-subnavitem---1cD47
  .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  svg {
  color: rgb(5, 124, 174) !important;
}

.sidenav---sidenav-subnav---1EN61
  .sidenav---selected---1EK3y
  .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  svg {
  color: rgb(255, 255, 255) !important;
}
