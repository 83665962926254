.component {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: white;
  padding: 20px;
  margin: 10px;
  height: 150px;
}
.fix-margin-top{
  margin-top: 0px !important;
}

.form-select-custom {
  background-color: #e9ecef;
}

.thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}


.td, th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.tbody tr:hover {
  background-color: #ddd;
}
